// Extra Small devices
@mixin xs {
   @media (max-width: #{$screen-xs-min}) {
       @content;
   }
}

// Small devices
@mixin sm {
   @media (max-width: #{$screen-sm-min}) {
       @content;
   }
}

// Medium devices
@mixin md {
   @media (max-width: #{$screen-md-min}) {
       @content;
   }
}

// Large devices
@mixin lg {
   @media (max-width: #{$screen-lg-min}) {
       @content;
   }
}

// Extra large devices
@mixin xl {
   @media (max-width: #{$screen-xl-min}) {
       @content;
   }
}

