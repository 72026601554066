#news{
    .bodyPost{
        img{
            padding: 10px;
            @include sm {
              width: 100%;
              height: auto;
            }
        }
    }
    .card{
        background: #F8F8F8;
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid rgba(216, 216, 216, 0.59);
    }
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
        justify-content: center;
      }
      .pagination > .active > a{
        background-color: #343434 ;
        border-color: #343434 ;
        color: #fff;
      }
      .pagination > li > a{
        border: 1px solid #343434 ;
        padding: 5px 10px;
        outline: none;
        cursor: pointer;
      }
      .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        background-color: #343434 ;
        border-color: #343434;
        outline: none ;
      }
      .pagination > li > a, .pagination > li > span{
        color: #343434
      }
      .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset
      }
      a {
        text-decoration: underline;
      }
 
}
.content a{
  text-decoration: underline;
}
.link-back{
  text-decoration: underline;
  &:hover{
    text-decoration: none;
    cursor: pointer;
  }
}
