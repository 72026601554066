body.blind{
    font-size: 25px;
    background-color: white;
    h5{
        font-size: 2.5rem;
    }
    h6{
        font-size: 2rem;
    }
    #news{
        font-size: 30px;
    }
}