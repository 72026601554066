.footer{
	background-color: #333333;
	color: #fbfbfb;
	p{
		color: #fbfbfb;
	}
	ul>li{
		padding: 10px 0;
	}
	a{
		color: #fbfbfb;
		text-decoration: underline;

		&:hover{
			text-decoration: none;
		}
	}
}