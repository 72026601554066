.home-slide{
 
}
.framevk{
    width: 100%;
    height: 500px;
}
#partner{
    @include sm{
        img{
            width: auto !important;
        }
    }
}