@import 'variables';
@import 'libs/normalize';
@import 'libs/intitickets';
@import 'fonts';
@import 'breakpoints';
@import 'mixins';

@import 'components/all';
@import 'pages/all';


body{
    background-color: $bodyColor;
    padding: 25px 50px;
    @include sm{
        padding: 10px 10px;
    }
}
h1{
    font-family: 'Cormorant Garamond', serif;
    font-weight: bold;
    text-transform: uppercase;
    @include xl{
        font-size: 40px;
    }
    @include lg{
        font-size: 30px;
    }
    @include xs{
        font-size: 20px;
    }
}
h3{
    font-weight: 500;
}
.mt-50{
    margin-top: 100px;
}
.dropdown-item {
    white-space: break-spaces;
}
#newsSingle {
    .content img{
        padding: 10px;
        max-width: 100%;
        @include sm{
            width: 100%;
            height: auto;
        }
    }
}
.artistList{
    list-style-position: inside;
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
}
.tm{
    color: red;
}